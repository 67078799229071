<template>
	<div class="w-full h-full flex flex-col">
		<Header>
			<div>
				<h1>Avdelinger</h1>
				<h3 v-if="signedInUserDepartments">Totalt {{signedInUserDepartments.length}} {{signedInUserDepartments.length > 1 ? 'avdelinger' : 'avdeling'}}</h3>
			</div>
		</Header>
		
		<div class="flex-grow overflow-auto p-6 md:p-12">
			<div 
				v-if="loading == 'fetching'"
				class="h-full flex items-center justify-center">
				
				<div class="spinner"></div>
			</div>
			<div 
				v-else-if="signedInUserDepartments && signedInUserDepartments.length"
				class="grid gap-6 md:gap-12 grid-cols-1">
				
				<router-link 
					v-for="d in signedInUserDepartments"
					:to="{ name: 'app-transactions', params: d }"
					:key="'department_'+d.departmentId">
					
					<DepartmentCard 
						:department="d"
					/>
				</router-link>
			</div>
			<div 
				v-else
				class="h-full flex items-center justify-center">
				
				Din bruker er ikke medlem av noen avdelinger
			</div>
		</div>
	</div>
</template>

<script>
import AuthMixins from '@/mixins/AuthMixins';

export default {
	mixins: [
		AuthMixins,
	],
	
	components: {
		'DepartmentCard': () => import('@/components/departments/DepartmentCard.vue'),
	},
	
	data(){
		return {
			loading: null,
			departmentMessages: [],
		}
	},
	
	methods: {
		onMarkMessageAsRead(message){
			localStorage['message_'+message.messageId] = new Date().getTime();
			this.departmentMessages = this.departmentMessages.filter( m => m.messageId != message.messageId);
		}
	},
	
	async mounted(){
		this.loading = 'fetching';
		await this.$store.dispatch('listDepartments');
		this.loading = null;
		
		if ( this.signedInUserDepartments && this.signedInUserDepartments.length == 1 ) {
			this.$router.push( { name: 'app-transactions', params: this.signedInUserDepartments[0] } );
		}
	},
}
</script>
